<template>
	<div class="container">
		<!-- <div v-if="mode === 'resetPassword'"> -->
			<h2 class="text-dark mx-auto my-5">Reset Password</h2>

			<b-card>
				<b-form>
					<b-form-group
						label="New Password"
						label-for="input-password"
						class="mb-3"
					>
						<b-form-input
							id="password"
							v-model="password"
							name="password"
							type="password"
							auto-complete="new-password"
							placeholder="Password"
							required
							data-testid="new-password-before-login"
						/>
					</b-form-group>
					<b-button
						variant="primary"
						block
						:disabled="canSubmit || isPasswordReset"
						type = "submit"
						@click="handleSubmitReset"
						data-testid="submit-reset-password"
					>
					<template v-if="isPasswordReset">
						<b-spinner small type="grow"></b-spinner>
						Resetting...
					</template>
					<template v-else>
						Reset Password
					</template>
					</b-button>
					<div v-if="passwordResetSuccessMsg" class="success-msg">Password Changed successfully</div>
					<div v-else class="error">{{ error }}</div>
					<div class="back-to-login"><b-link @click="$router.push({ name: 'login' })" data-testid="back-to-login-link">
						<b-icon-arrow-left-circle data-testid="back-to-login-icon" class="arrow-icon"></b-icon-arrow-left-circle>Back to Login
						</b-link></div>
				</b-form>
			</b-card>
		<!-- </div> -->
		<!-- <div v-else>
			<h2 class="text-dark mx-auto my-5">Something went wrong</h2>
		</div> -->
	</div>
</template>

<script>
import { auth } from '../firebase';
import { BIconArrowLeftCircle } from 'bootstrap-vue';
export default {
	components: {BIconArrowLeftCircle},
	data() {
		const { oobCode, mode } = this.$route.query;
		return {
			mode,
			oobCode,
			email: '',
			password: '',
			error: null,
			passwordResetSuccessMsg: false,
			isPasswordReset: false,
		};
	},
	computed: {
		canSubmit() {
			return !(this.password && this.password.length >= 6);
		},
	},
	mounted() {
		auth.verifyPasswordResetCode(this.oobCode)
			.then(email => {
				this.email = email;
			})
			.catch(error => {
				console.error(error);
			});
	},
	methods: {
		async handleSubmitReset() {
			this.isPasswordReset = true;
			auth.confirmPasswordReset(this.oobCode, this.password)
			.then(() => {
				this.isPasswordReset = false;
				this.passwordResetSuccessMsg = true;
			})
			.catch(
				(error) => {
					this.isPasswordReset = false;
					this.passwordResetSuccessMsg = false;
					this.error = error.message;
					console.error("Error:", this.error)
					// if (error.code === 'auth/expired-action-code') {
					// 	this.error = 'Reset Code has Expired.';
					// } else if (error.code === 'auth/invalid-action-code') {
					// 	this.error = 'Invalid code';
					// } else if (error.code === 'auth/user-disabled') {
					// 	this.error =
					// 		'User has been disabled please contact support.';
					// } else if (error.code === 'auth/user-not-found') {
					// 	this.error = 'User not found.';
					// } else if (error.code === 'auth/weak-password') {
					// 	this.error = 'Password is too weak.';
					// } else {
					// 	this.error =
					// 		'An Unexpected Error has occured. Please try again.';
					// }
				}
			);
			// try {
			// 	await auth.signInWithEmailAndPassword(
			// 		this.email,
			// 		this.password
			// 	);
			// } catch (error) {
			// 	var errorCode = error.code;
			// 	var errorMessage = error.message;

			// 	if (errorCode === 'auth/user-not-found') {
			// 		this.error = 'No user found with that email.';
			// 	} else if (
			// 		errorMessage === 'INVALID_PASSWORD' ||
			// 		errorMessage === 'EMAIL_NOT_FOUND' ||
			// 		errorCode === 'auth/wrong-password'
			// 	) {
			// 		this.error = 'Invalid username or password';
			// 	}
			// }
		},
	},
};
</script>
<style scoped>
.container{
	max-width: 500px;
}
.success-msg{
	padding-top: 10px;
	color: green;
}
.error{
	padding-top: 10px;
	color: red;
}
.back-to-login{
	text-align: center;
    padding-top: 10px;
}
.arrow-icon{
    padding-right: 3px;
}
</style>